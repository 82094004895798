import React, { useState } from 'react'
import Modal from 'components/ui/Modal'
import { humanize } from 'utils/string'
import { useSelector } from 'react-redux'

const TASK_ENTITY_MAP = {
    origins: 'import_origins',
    boxes: 'import_boxes',
    customer_groups: 'import_customer_groups',
    shipping_groups: 'import_shipping_groups',
    ship_filters: 'import_ship_filters',
    zones: 'import_zones',
    table_rates: 'import_table_rates',
    locations: 'import_locations',
}

const MAX_LOCATIONS_IMPORT_ROWS = 450

export const ImportFormModal = ({
    error,
    onClose,
    open,
    title,
    entityType,
    onSubmit,
}) => {
    const [file, setFile] = useState(null)
    const { import_max_rows_limits } = useSelector(({ app }: any) => app)
    const taskName = TASK_ENTITY_MAP[entityType]

    // TODO: Locations page doesn't support useSelector
    const maxRowsLimit =
        entityType == 'locations'
            ? MAX_LOCATIONS_IMPORT_ROWS
            : import_max_rows_limits[taskName]

    if (!open) return null

    const isValid = !!file

    const onFileUpload = (event) => {
        setFile(event.target.files[0])
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        onSubmit({ file })
    }

    return (
        <Modal onClose={onClose}>
            <div>
                {error && (
                    <div className="alert alert-danger">
                        <ul>
                            <li className="text-left">
                                <span className="error-color">{error}</span>
                            </li>
                        </ul>
                    </div>
                )}

                {title && <h3>{title}</h3>}

                <div className="well well-sm">
                    There is a maximum limit of {maxRowsLimit}{' '}
                    {humanize(entityType)} that can be uploaded per CSV at a
                    time. If needed, you can upload additional CSVs that contain
                    more records. If an record name in the new list matches one
                    that has already been uploaded the new record will NOT be
                    added, alternatively, if the new record name is unique it
                    will be appended to the list.
                </div>

                <div className="form-group file required align-left mb-6">
                    <label
                        className="control-label file required"
                        htmlFor="csv_file_object_csv_file"
                    >
                        Csv file
                    </label>
                    <div>
                        <input
                            onChange={onFileUpload}
                            className="form-control file required form-control"
                            type="file"
                            name="csv_file"
                        />
                    </div>
                </div>

                <div>
                    <button
                        disabled={!isValid}
                        className="btn btn-primary mb-2"
                        onClick={handleSubmit}
                        type="submit"
                    >
                        Upload
                    </button>

                    {onClose && (
                        <a
                            href="#"
                            data-testid="cancelImportFormModal"
                            className="sub-action mb-0"
                            onClick={onClose}
                        >
                            Cancel
                        </a>
                    )}
                </div>
            </div>
        </Modal>
    )
}
