import { useQuery, useMutation } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

const createImportTask = (variables) => {
    let data = new FormData()
    data.append('import_file', variables.file)
    data.append('append_rates', variables.append_rates)
    data.append('carrier_id', variables.carrier_id)

    return axiosClient.post(
        `/ratesmgr/import_tasks/${variables.entityType}`,
        data
    )
}

const removeImportTask = (entityType) => {
    return axiosClient.delete(`/ratesmgr/import_tasks/${entityType}`)
}

const getImportTask = (entityType) =>
    axiosClient
        .get(`/ratesmgr/import_tasks/${entityType}`)
        .then(({ data }) => data)

export const useCreateImportTask = () => {
    const props = useMutation(createImportTask)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        createImportTask: props.mutate,
    }
}

export const useRemoveImportTask = () => {
    const props = useMutation(removeImportTask)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        removeImportTask: props.mutate,
    }
}

export const useGetImportTask = (id) => {
    const { data, ...rest } = useQuery(['import_task', id], () =>
        id ? getImportTask(id) : null
    )

    let importTask: ImportTask = data

    return { importTask, ...rest }
}
