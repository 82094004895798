import React, { useState } from 'react'
import classnames from 'classnames'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import { toTitleCase } from 'utils/string'
import Panel from 'components/ui/Panel'

interface ImportSummaryProps {
    importTask: ImportTask
    onClose: React.MouseEventHandler
    onBack: React.MouseEventHandler
}

export const ImportSummary: React.FC<ImportSummaryProps> = ({
    importTask,
    onClose,
    onBack,
}) => {
    const errors: any = importTask?.errors || {}
    const totalFailure = importTask?.failure_count || 0
    const totalCount = importTask?.total_count || 0
    const importedSuccess = importTask?.imported_count || 0
    const updatedSuccess = importTask?.updated_count || 0
    const additionalInfo = importTask?.additional_info || {}

    const isAnyError =
        !isEmpty(errors) &&
        Object.keys(errors).filter((key) => key !== 'base').length > 0

    return (
        <>
            {importTask.status === 'succeed' && (
                <div className="success-msg">
                    <h4>
                        <i className="fas fa-check-circle mr-2" />
                        Import complete!
                    </h4>

                    <div>All rows have been imported</div>
                </div>
            )}

            {errors.base && (
                <div className="alert alert-danger">
                    <ul>
                        <li className="text-left">
                            <span className="error-color">
                                {flatten([errors.base]).join(', ')}
                            </span>
                        </li>
                    </ul>
                </div>
            )}

            <Panel
                title="Import Summary"
                footer={
                    isAnyError && (
                        <ImportSummaryErrors
                            rowErrors={buildErrorsEntities(importTask.errors)}
                        />
                    )
                }
            >
                <div className="col-xs-6 align-left">
                    <strong>File Name:</strong> {importTask.file_name}
                    <br />
                    <strong>Rows Count:</strong> {totalCount}
                    <br />
                    <strong>Rows Imported:</strong> {importedSuccess}
                    <br />
                    <strong>Rows Updated:</strong> {updatedSuccess}
                    {totalFailure > 0 && (
                        <>
                            <br />
                            <strong>{`Entries in Error: ${totalFailure}`}</strong>
                        </>
                    )}
                    <br />
                    {Object.keys(additionalInfo).map((label, index) => (
                        <p className="mb-0" key={`${label}-${index}`}>
                            <strong>{`${toTitleCase(label)}:`}</strong>{' '}
                            {additionalInfo[label]}
                            <br />
                        </p>
                    ))}
                </div>

                {!isEmpty(importTask.summary) && (
                    <div className="col-xs-6 align-left">
                        {Object.keys(importTask.summary).map((label, index) => (
                            <p key={`${label}-${index}`}>
                                <strong>{`${label}:`}</strong>
                                {importTask.summary[label]}
                                <br />
                            </p>
                        ))}
                    </div>
                )}
            </Panel>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <button
                    type="button"
                    className="btn btn-primary mb-2"
                    onClick={onBack}
                >
                    Hide report
                </button>

                <button type="button" className="btn" onClick={onClose}>
                    Close
                </button>
            </div>
        </>
    )
}

export const ImportSummaryErrors = ({ rowErrors }) => {
    const [showMoreErrors, setShowMoreErrors] = useState(false)
    const shortErrorsCount = 5

    const toggleMoreErrors = () => setShowMoreErrors(!showMoreErrors)

    const renderEntities = showMoreErrors
        ? rowErrors
        : (rowErrors || []).slice(0, shortErrorsCount)

    const moreErrorsCount = rowErrors.length - shortErrorsCount

    return (
        <table className="table table-striped">
            <tbody>
                <tr>
                    <th>Entry</th>
                    <th>Status</th>
                    <th>Message</th>
                </tr>

                {renderEntities.map((row) => (
                    <tr
                        data-testid={`rowError-${row.row}`}
                        key={row.row}
                        className="danger"
                    >
                        <td>Row: {row.row}</td>
                        <td>Errors</td>
                        <td>
                            <ul>
                                <RowErrors rowErrors={row.errors} />
                            </ul>
                        </td>
                    </tr>
                ))}

                {!showMoreErrors && moreErrorsCount > 0 && (
                    <tr>
                        <td className="text-center" colSpan={3}>
                            <p
                                onClick={toggleMoreErrors}
                                className="error-color hover mb-0"
                            >
                                {`${moreErrorsCount} more errors found`}
                            </p>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

const RowErrors = ({ rowErrors }: { rowErrors: any | any[] }) => {
    if (Array.isArray(rowErrors)) {
        return (
            <>
                {rowErrors.map((error, idx) => (
                    <li
                        className={classnames('text-left small', {
                            'mb-1': idx !== rowErrors.length - 1,
                        })}
                        key={idx}
                    >
                        <span className="error-color">{error}</span>
                    </li>
                ))}
            </>
        )
    }

    return (
        <>
            {Object.keys(rowErrors).map((errorKey, idx) =>
                flatten([rowErrors[errorKey]]).map((errorMessage, errorIdx) => (
                    <li
                        key={`${idx}-${errorIdx}`}
                        className={classnames('text-left small', {
                            'mb-1': idx !== Object.keys(rowErrors).length - 1,
                        })}
                    >
                        <>
                            {errorKey && errorKey !== 'base' && (
                                <strong className="mr-1">
                                    {`${toTitleCase(errorKey)}:`}
                                </strong>
                            )}
                            <span className="error-color">{errorMessage}</span>
                        </>
                    </li>
                ))
            )}
        </>
    )
}

const buildErrorsEntities = (errors) =>
    Object.keys(errors).map((row) => ({
        row,
        errors: flatten([errors[row]]),
    }))
