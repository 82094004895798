//
import * as React from 'react'

/**
 * Panel Properties
 */

const Panel = ({ title, children, footer }) => (
    <div className="panel panel-info">
        <div className="panel-heading">
            <h3 className="panel-title">{title}</h3>
        </div>
        <div className="panel-body">{children}</div>
        {footer ? footer : null}
    </div>
)

export default Panel
