import { useState, useEffect } from 'react'
import {
    useCreateImportTask,
    useGetImportTask,
    useRemoveImportTask,
} from 'hooks/useImportTasks'

interface useImportButtonProps {
    entityType: string
    onRun?: (id) => void
    onClose?: () => void
}

export const useImportButton = ({
    entityType,
    onRun,
    onClose,
}: useImportButtonProps) => {
    const [additionalProperties, setAdditionalProperties] = useState({})
    const [modalOpened, setModalOpened] = useState(false)
    const [loading, setLoading] = useState(false)
    const [importTask, setImportTask] = useState(null)
    const [importError, setImportError] = useState(null)
    const { createImportTask } = useCreateImportTask()
    const { removeImportTask } = useRemoveImportTask()

    const {
        importTask: importTaskPayload,
        isLoading,
        refetch,
    } = useGetImportTask(entityType)

    const isFormModal = !importTask
    const isReportModal = !!importTask

    const toggleOpenedModal = () => {
        refetch()

        const nextOpened = !modalOpened

        if (nextOpened && importTask?.finished) {
            handleRemoveTask()
            setImportTask(null)
        }

        setImportError(null)
        setModalOpened(nextOpened)
    }

    const [shouldReloadOnClose, setShouldReloadOnClose] = useState(
        importTask?.finished
    )

    const handleCloseModal = () => {
        if (onClose) {
            onClose()
        } else {
            if (shouldReloadOnClose) window.location.reload()
        }

        setModalOpened(false)
    }

    const handleReportClose = () => {
        handleCloseModal()
    }

    const handleRemoveTask = () => {
        setImportError(null)

        if (importTask?.finished) {
            setShouldReloadOnClose(true)
        }

        removeImportTask(entityType, {
            onSuccess: () => setImportTask(null),
            onError: () => setImportTask(null),
        })
    }

    const handleImport = ({ file }) => {
        if (loading) return

        setLoading(true)

        const data = { file, entityType, ...additionalProperties }

        createImportTask(data, {
            onSuccess: ({ data }: { data: any }) => {
                onRun && onRun(data.action_progress_id)
                setLoading(false)
                refetch()
            },
            onError: ({ errors }) => {
                setImportError(errors.join(', '))
                setLoading(false)
            },
        })
    }

    useEffect(() => {
        setImportTask(importTaskPayload)
        setShouldReloadOnClose(!!importTaskPayload?.finished)
    }, [importTaskPayload])

    return {
        loading,
        toggleOpenedModal,
        modalOpened,
        handleCloseModal,
        handleImport,
        importError,
        importTask,
        refetch,
        isLoading,
        handleReportClose,
        handleRemoveTask,
        isFormModal,
        isReportModal,
        additionalProperties,
        setAdditionalProperties,
    }
}
