import React from 'react'
import Modal from 'components/ui/Modal'
import { useActionProgressModal } from 'components/ActionProgressModal'
import ProgressIndicator from 'components/ActionProgressModal/ProgressIndicator'

import { ImportSummary } from './ImportSummary'

interface MigrateProgressModalProps {
    importTask: ImportTask
    refetch: any
    isLoading: boolean
    open: boolean
    onClose: React.MouseEventHandler
    onBack: React.MouseEventHandler
}

export const ImportProgressModal: React.FC<MigrateProgressModalProps> = ({
    importTask,
    refetch,
    isLoading,
    open,
    onClose,
    onBack,
}) => {
    const { inProgress, processLabel, processDescription, progress } =
        useActionProgressModal({
            open,
            actionProgress: importTask,
            onRefetch: refetch,
        })

    if (!importTask || !open || isLoading) return null

    return (
        <div className="scope-sect">
            <Modal onClose={onClose} className="modal-content">
                {inProgress ? (
                    <ProgressIndicator
                        name={processLabel}
                        progress={progress}
                        description={processDescription}
                    />
                ) : (
                    <ImportSummary
                        onBack={onBack}
                        onClose={onClose}
                        importTask={importTask}
                    />
                )}
            </Modal>
        </div>
    )
}
